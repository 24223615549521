import React, { useEffect, useState, useCallback } from 'react';
import ToolBar from 'components/advanced/toolbar/ToolBar';
import ActiveFilters from 'components/advanced/activefilters/ActiveFilters';
import Filters from 'components/advanced/filters/Filters';
import DataSource from 'components/advanced/datasource/DataSource';
import Presentation from 'components/advanced/presentation/Presentation';
import ReportSection from 'components/advanced/reportsection/ReportSection';
import Explanations from 'components/advanced/explanations/Explanations';
import CustomPresentation from 'components/advanced/custompresentation/CustomPresentation';
import Statisticsometer from 'atoms/statisticsometer/Statisticsometer';
import { useAuth } from 'services/UseAuth';
import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { numberOfRespondents } from 'lib/antal';
import { resetMeasureVarToLon, logGetReportRequest } from 'lib/pageAdvanced';
import isEmpty from 'lib/emptyValues';
import styles from './PageAdvanced.module.css';
import Notification from '../../atoms/notification/Notification';
import ErrorNotification from '../../atoms/errornotification/ErrorNotification';

const PageAdvanced = () => {
  const [filterList, setFilterList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);

  const [dataSources, setDataSources] = useState([]);
  const [activeDataSource, setActiveDataSource] = useState();
  const [measureVar, setMeasureVar] = useState('LON');
  const [activeFilters, setActiveFilters] = useState([]);
  const [disableMenuselection, setDisableMenuselection] = useState(false);

  const [, setState] = useState();
  const { user, signout, autoRefreshToken } = useAuth();
  const { forbund, token } = user;

  const [totalRespondents, setTotalRespondents] = useState(1);
  const [currentRespondents, setCurrentRespondents] = useState(1);

  const [presentationList, setPresentationList] = useState();
  const [selectedPresentation, setSelectedPresentation] = useState([]);
  const [allowCustomPresentation, setAllowCustomPresentation] = useState(false);
  const [customPresentation, setCustomPresentation] = useState(false);

  const [selectedUnion, setSelectedUnion] = useState(null);

  const [shouldGetReportData, setShouldGetReportData] = useState(true);

  const [
    defaultYearFromDeepAnalysis,
    setDefaultYearFromDeepAnalysis,
  ] = useState(0);
  const [defaultYearToDeepAnalysis, setDefaultYearToDeepAnalysis] = useState(0);

  const sortedSelectedValues = activeFilters.reduce((acc, curr) => {
    acc[curr.sel_var] = curr.values.length;
    return acc;
  }, {});

  const hasPartiallyFilledFilters = filterList.some(
    filter =>
      sortedSelectedValues[filter.sel_var] &&
      sortedSelectedValues[filter.sel_var] < filter.list.length
  );

  const history = useHistory();

  const handleError = useCallback(
    error => {
      if (error.status === 401) {
        signout();
        history.push('/logga-ut');
      } else {
        setState(() => {
          console.error(error);
        });
      }
    },
    [signout, history]
  );

  // Någon slags init.
  useEffect(() => {
    let active = true;

    if (active) {
      const promiseUnion = new Promise((resolve, reject) =>
        ApiService.getUnion(user.token)
          .then(data => resolve(data))
          .catch(error => reject(handleError(error)))
      );

      const request = {
        forbund: [forbund, 'SA'],
      };

      const promiseFds = new Promise((resolve, reject) =>
        ApiService.getActiveFds(request, token)
          .then(data => resolve(data))
          .catch(error => reject(handleError(error)))
      );

      Promise.all([promiseUnion, promiseFds]).then(([unionData, fdsData]) => {
        setSelectedUnion(unionData);
        setDataSources(fdsData);

        // Admin can define a default source in the admin panel
        const defaultSource =
          fdsData.find(
            ds =>
              ds.YEAR === unionData.defaultDataSource?.year &&
              ds.DSL1 === unionData.defaultDataSource?.DSL1 &&
              ds.DSL2 === unionData.defaultDataSource?.DSL2 &&
              ds.FORBUND === unionData.forbundskodSas
          ) ||
          // DSL2 Isn't required
          fdsData.find(
            ds =>
              ds.YEAR === unionData.defaultDataSource?.year &&
              ds.DSL1 === unionData.defaultDataSource?.DSL1 &&
              ds.FORBUND === unionData.forbundskodSas
          ) ||
          // If union doesn't have the data source
          fdsData.find(
            ds =>
              ds.YEAR === unionData.defaultDataSource?.year &&
              ds.DSL1 === unionData.defaultDataSource?.DSL1 &&
              ds.DSL2 === unionData.defaultDataSource?.DSL2
          ) ||
          // DSL2 Isn't required
          fdsData.find(
            ds =>
              ds.YEAR === unionData.defaultDataSource?.year &&
              ds.DSL1 === unionData.defaultDataSource?.DSL1
          ) ||
          // Datasources can also be flagged as "DEFAULT" in SAS
          fdsData
            .filter(ds => ds.YEAR === unionData.defaultYearToDeepAnalysis)
            .find(ds => ds.DEFAULT === 1) ||
          // The config is optional in both SAS and the admin panel, the standard year isn't
          fdsData.find(ds => ds.YEAR === unionData.defaultYearToDeepAnalysis) ||
          fdsData.sort((a, b) => b.YEAR - a.YEAR)[0];

        setActiveDataSource({
          forbund: defaultSource.FORBUND,
          dsl1: defaultSource.DSL1,
          dsl2: defaultSource.DSL2,
          measureVar: defaultSource.measure_var,
          year: defaultSource.YEAR,
        });

        setDefaultYearFromDeepAnalysis(unionData.defaultYearFromDeepAnalysis);
        setDefaultYearToDeepAnalysis(unionData.defaultYearToDeepAnalysis);
        setAllowCustomPresentation(unionData.allowCustomPresentation);
      });

      window.scrollTo(0, 0);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, []);

  // Set selected presentation if theres a list
  useEffect(() => {
    if (presentationList && presentationList.length > 0) {
      if (!selectedPresentation.length) {
        setSelectedPresentation([presentationList[0]]);
      }
    }
  }, [presentationList]);

  const handleDataSourceChange = newDataSource => {
    setActiveDataSource(newDataSource);

    if (presentationList && presentationList.length > 0) {
      const isSelectedBreakbyAvailable = presentationList.some(
        presentation =>
          presentation.BREAKBY_VAR === selectedPresentation[0]?.BREAKBY_VAR
      );

      if (!isSelectedBreakbyAvailable) {
        setSelectedPresentation([presentationList[0]]);
      }
    } else {
      setSelectedPresentation([]);
    }
  };

  // Get configured menuselections
  useEffect(() => {
    let active = true;

    console.log("useEffect activeDataSource", activeDataSource);
    if (activeDataSource != null) {
      resetMeasureVarToLon(activeDataSource, setMeasureVar);

      setDisableMenuselection(true);

      const request = {
        Forbund: activeDataSource.forbund,
        Dsl1: activeDataSource.dsl1,
        Dsl2: activeDataSource.dsl2, // FIXME: Vad händer när dsl2 är null?
        Year: activeDataSource.year,
        Role: user.role === 'fortoendevald' ? 'fortoendevald' : 'medlem',
      };

      if (active && activeFilters.length === 0) {
        ApiService.getPresentations(request, token)
          .then(data => {
            // Configured filters.
            const filters = data.filters
              .filter(filter => filter.isActive)
              .sort((a, b) => a.filterOrder - b.filterOrder)
              .reduce((acc, curr) => {
                if (acc[curr.sel_var] == null) {
                  acc[curr.sel_var] = {
                    options: curr.options
                      .map(opt => {
                        return {
                          ...opt,
                          sel_var: curr.sel_var,
                          SELECTION_DATATYPE: curr.SELECTION_DATATYPE,
                          name: curr.SELECTION_TEXT,
                          value: opt.value,
                        };
                      })
                      .filter(opt => !isEmpty(opt.value))
                      .sort((a, b) =>
                        curr.sortOptionsByNumber
                          ? Number(a.value) - Number(b.value)
                          : a.description.localeCompare(b.description, 'sv')
                      ),
                    name: curr.SELECTION_TEXT,
                    sel_var: curr.sel_var,
                  };
                } else {
                  acc[curr.sel_var].options.push(...curr.options);
                }
                return acc;
              }, {});
            const filterObjects = Object.values(filters).map(obj => ({
              list: [...obj.options],
              name: obj.name,
              sel_var: obj.sel_var,
            }));
            setFilterList(filterObjects);

            // Configured breakbys.
            if (data.breakbys.length > 0) {
              setPresentationList(
                data.breakbys
                  .filter(breakbyItem => breakbyItem.isActive)
                  .sort((a, b) => {
                    if (a.breakbyOrder != null && b.breakbyOrder != null) {
                      return a.breakbyOrder - b.breakbyOrder;
                    }
                    return `${a.REPORT_TEXT}`.localeCompare(
                      b.REPORT_TEXT,
                      'sv'
                    );
                  })
                  .map(
                    ({
                      breakbyVar,
                      breakbyText,
                      sortOptionsByLetters,
                      isReverse,
                    }) => ({
                      BREAKBY_VAR: breakbyVar,
                      REPORT_TEXT: breakbyText,
                      sortOptionsByLetters,
                      isReverse,
                    })
                  )
              );
            } else {
              // FIXME: Agera på detta. Ibland är listan över breakbys tom
            }

            // Update total number.
            const dataSource = dataSources.find(
              source =>
                source.FORBUND === activeDataSource.forbund &&
                source.DSL1 === activeDataSource.dsl1 &&
                source.DSL2 === activeDataSource.dsl2 &&
                source.YEAR === activeDataSource.year
            );

            if (dataSource) {
              setTotalRespondents(dataSource.total);
            }
          })
          .catch(error => handleError(error))
          .finally(() => setDisableMenuselection(false));
      }
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [activeDataSource, activeFilters]);

  useEffect(() => {
    let active = Boolean(filterList.length && activeFilters.length);

    if (activeDataSource != null) {
      let filter;
      if (
        activeFilters.length > 0 &&
        activeFilters.filter(({ values }) => values.length).length
      ) {
        filter = activeFilters
          .filter(activeFilter => activeFilter.values.length)
          .map(activeFilter => {
            const s = activeFilter.values.reduce((acc, curr) => {
              return `${
                activeFilter?.selection_datatype === 'N'
                  ? curr.value
                  : `'${curr.value}'`
              }${acc ? `, ${acc}` : ''}`;
            }, null);

            return `${activeFilter.sel_var} in (${s})`;
          });
      }

      const request = {
        Forbund: activeDataSource.forbund,
        Dsl1: activeDataSource.dsl1,
        Dsl2: activeDataSource.dsl2,
        Year: activeDataSource.year,
        filter,
      };

      if (active) {
        setDisableMenuselection(true);

        // Only used to update "antal" (counter) for each menuselection when changes are made.
        ApiService.getMenuselections(request, token)
          .then(data => {
            const filters = data
              .filter(fltr =>
                filterList.some(
                  activeFltr => fltr.sel_var === activeFltr.sel_var
                )
              )
              .reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.sel_var]: acc[curr.sel_var]
                    ? [...acc[curr.sel_var], curr]
                    : [curr],
                }),
                {}
              );

            if (!Object.keys(filters).length) return;
            const newState = filterList.map(fltr => {
              const newOptions = filters[fltr.sel_var];
              if (newOptions)
                return {
                  name: fltr.name,
                  sel_var: fltr.sel_var,
                  list: fltr.list.map(listItem => {
                    const newOpt = newOptions.find(
                      opt => opt.value === listItem.value
                    );

                    return {
                      ...listItem,
                      ...(newOpt && {
                        antal: newOpt.antal,
                        selectable: newOpt.selectable,
                      }),
                    };
                  }),
                };

              return fltr;
            });

            setFilterList(newState);
          })
          .catch(error => handleError(error))
          .finally(() => setDisableMenuselection(false));
      }
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [activeFilters]);

  useEffect(() => {
    let active = true;

    if (shouldGetReportData === true) {
      if (
        presentationList != null &&
        selectedPresentation != null &&
        activeDataSource != null
      ) {
        let filter;

        if (
          activeFilters.length > 0 &&
          activeFilters.filter(({ values }) => values.length).length
        ) {
          filter = activeFilters
            .filter(activeFilter =>
              filterList.some(
                fltr =>
                  activeFilter.values.length &&
                  fltr.sel_var === activeFilter.sel_var
              )
            )
            .map(activeFilter => {
              const s = activeFilter.values.reduce((acc, curr) => {
                return `${
                  activeFilter?.selection_datatype === 'N'
                    ? curr.value
                    : `'${curr.value}'`
                }${acc ? `, ${acc}` : ''}`;
              }, null);

              return `${activeFilter.sel_var} in (${s})`;
            });
        }

        const request = {
          Forbund: activeDataSource.forbund,
          Dsl1: activeDataSource.dsl1,
          Dsl2: activeDataSource.dsl2,
          measure_var: measureVar,
          Year: activeDataSource.year,
          breakby1: selectedPresentation[0].BREAKBY_VAR
            ? selectedPresentation[0].BREAKBY_VAR
            : null,
          breakby2:
            selectedPresentation.length > 1
              ? selectedPresentation[1].BREAKBY_VAR
              : null,
          filter,
        };

        if (active) {
          setFetchingData(true);

          logGetReportRequest(request);

          ApiService.getReport(request, token)
            .then(data =>
              setTableData(() => {
                if (
                  selectedPresentation.length > 0 &&
                  selectedPresentation[0].sortOptionsByLetters
                ) {
                  return data.sort(
                    (a, b) =>
                      a.breakby1 && a.breakby1.localeCompare(b.breakby1, 'sv')
                  );
                }
                return data;
              })
            )
            // TODO: Just check if token is about to expire once, this code is a little everywhere
            .then(() => autoRefreshToken(token))
            .catch(error => handleError(error))
            .finally(() => setFetchingData(false));
        }
      }
    } else {
      setShouldGetReportData(true);
    }

    return () => {
      active = false;
    };

    // eslint-disable-next-line
  }, [activeFilters, selectedPresentation, measureVar]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      const current = Math.ceil(
        []
          .concat(
            ...activeFilters
              .slice(activeFilters.length - 1)
              .map(item => item.values)
          )
          .reduce((a, b) => {
            const low = numberOfRespondents(b.antal)[0];
            const high = numberOfRespondents(b.antal)[1];
            const diff = (high - low) / 2;

            if (Number(low) && Number(high)) {
              return a + low + diff;
            }

            return a;
          }, 0)
      );
      setCurrentRespondents(current);
    } else {
      setCurrentRespondents(totalRespondents);
    }
    // eslint-disable-next-line
  }, [activeFilters, totalRespondents]);

  useEffect(() => {
    setShouldGetReportData(false);
    setActiveFilters([]);
  }, [activeDataSource]);

  // Tillåt ej "Egen rapport" om förbundet är SI (CF) och alternativ valt är chefslöner eller nyexaminerade
  useEffect(() => {

    if (activeDataSource) {
      if (
        (activeDataSource.forbund === 'CF' && activeDataSource.dsl2 === 'CH') ||
        activeDataSource.dsl2 === 'FJ'
      ) {
        setAllowCustomPresentation(false);
      } else if (selectedUnion.allowCustomPresentation) {
        setAllowCustomPresentation(true);
      } else {
        setAllowCustomPresentation(false);
      }
    }
  }, [activeDataSource]);

  // Disable CustomPresentation (Egen rapport) when current active data source is changed
  useEffect(() => {
    setCustomPresentation(false);
  }, [activeDataSource]);

  return (
    <div className={styles.main}>
      <div className={styles.innerContainerFull}>
        <Notification
          token={token}
          forbund={forbund}
          niva="advanced"
          dataSource={activeDataSource}
        />
        <ToolBar
          tableData={tableData}
          selectedPresentation={selectedPresentation}
          activeFilters={activeFilters}
          activeDataSource={activeDataSource}
          measureVar={measureVar}
          dataSources={dataSources}
        />
        <section className={styles.mainSection}>
          <div className={styles.subSectionLeft}>
            {activeDataSource && (
              <DataSource
                activeDataSource={activeDataSource}
                dataSources={dataSources}
                defaultYearFromDeepAnalysis={defaultYearFromDeepAnalysis}
                defaultYearToDeepAnalysis={defaultYearToDeepAnalysis}
                disableMenuselection={disableMenuselection}
                setActiveDataSource={setActiveDataSource}
                handleDataSourceChange={handleDataSourceChange}
              />
            )}
            <Filters
              activeFilters={activeFilters}
              disableMenuselection={disableMenuselection}
              filterList={filterList}
              setActiveFilters={setActiveFilters}
            />
          </div>
          <div className={styles.subSectionRight}>
            <div className={styles.subSectionContent}>
              {activeFilters.length > 0 && (
                <ActiveFilters
                  activeFilters={activeFilters}
                  disableMenuselection={disableMenuselection}
                  setActiveFilters={setActiveFilters}
                />
              )}
              <div className={styles.reportSettings}>
                <Statisticsometer
                  currentRespondents={currentRespondents}
                  hasPartiallyFilledFilters={hasPartiallyFilledFilters}
                  totalRespondents={totalRespondents}
                  variant="advanced"
                />
                <Presentation
                  allowCustomPresentation={allowCustomPresentation}
                  presentationList={presentationList}
                  selectedPresentation={selectedPresentation}
                  setSelectedPresentation={setSelectedPresentation}
                  setCustomPresentation={setCustomPresentation}
                  fetchingData={fetchingData}
                />
              </div>
              {customPresentation && (
                <CustomPresentation
                  presentationList={presentationList}
                  setSelectedPresentation={setSelectedPresentation}
                />
              )}
              <ReportSection
                activeDataSource={activeDataSource}
                customPresentation={customPresentation}
                datasetLabel={
                  selectedPresentation && selectedPresentation.REPORT_TEXT
                }
                fetchingData={fetchingData}
                measureVar={measureVar}
                selectedPresentation={selectedPresentation}
                setMeasureVar={setMeasureVar}
                tableData={tableData}
              />
              <Explanations
                filterList={filterList}
                forbund={forbund}
                id="hjalp"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PageAdvanced;
